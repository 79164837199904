@media screen and (max-width: 768px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
    
  }
.custom-nav {
  background-color: transparent;
}
  #portfolio {
    width: 100%;
  }
} 
